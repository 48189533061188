<template>
  <div>
    <div class="vx-row  ">

      <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <p class="text-xl">
        {{ $t("Prescriptions") }}
      </p>
    </div>
    <div class="vx-card ml-2 vx-row mt-4 justify-center w-full">
      <span class="mt-6 mr-2 text-lg">{{$t("SearchBy")}}  </span>
      <div class="w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4 mt-4">
        <vs-input
          name="Doctor Name"
          class="lg:w-3/4 w-full"
          :placeholder="$t('DoctorName')"
          v-model="Search.DoctorName"
        />
      </div>
      <span class="mt-3 mr-24 text-lg">  {{$t("Or")}}  </span>
      <div class="vx-row w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4 mt-4">
       <p class="mt-3 lg:w-1/8 w-full ml-2">{{$t('From')}}</p>
         <datepicker
        :placeholder="$t('DateFrom')"
        v-model="Search.DateFrom"
        class="lg:w-1/3 w-full m-1 mt-1"
        name="DateFrom"
      ></datepicker>
       <p class="mt-3 lg:w-1/7 w-full  ml-2" >{{$t('To')}}</p>

       <datepicker
        :placeholder="$t('DateTo')"
        v-model="Search.DateTo"
        class="lg:w-1/3 w-full m-1 mt-1"
        name="DateTo"
      ></datepicker>
      </div>
      <div class="w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4 mt-4">
        <feather-icon icon="SearchIcon" @click.stop="SearchPrescription()" svgClasses=" w-16" class="mt-3 " />
      </div>
    </div>
<h3  v-if="Model.length==0"  class="w-full sm:w-auto text-center text-primary mt-5 mb-5">{{$t("NoDataToshow")}}</h3>
    <div class="grid-layout-container alignment-block">
      <vs-row
        vs-align="center"
        vs-type="flex"
        vs-justify="center"
        vs-w="12"

        v-for="(item, index) in FilteredList"
        :key="index"
      >
        <vs-col
          vs-type="flex"
          vs-justify="start"
          vs-align="center"
          vs-w="3"
          vs-sm="6"
          class="mt-4"
        >
          {{ item.Note }}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
           vs-sm="2"
          class="mt-4"
        >
          {{ item.Date | formatDate() }}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="0"
           vs-sm="2"
          class="mt-4"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
          class="mt-4"
           vs-sm="2"
        >
          <div>
            <vs-avatar size="large" :src="baseURL + item.DoctorImage" />
          </div>
          {{ item.DoctorName }}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
          vs-sm="12"
          class="mt-4"

        >
          <vs-button
            @click="Download(item, index)"
            color="primary"
            class="text-white"
            text-color="rgb(69, 74, 98)"
            icon-pack="icon icon-download"
            icon="feather"
            >{{ $t("Download") }}
          </vs-button>
        </vs-col>
        <vs-divider class="w-2/3" />
      </vs-row>
    </div>

    <div class="vx-card m-4 py-4">
      <vs-row vs-align="center" vs-type="flex" vs-justify="center" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="mt-4"
        >
          <vs-button
            type="flat"
            class="text-black"
            icon-pack="feather"
            icon="icon-chevrons-left"
            :disabled="this.CurrentPage == 1"
            @click="GoToFirstPage()"
          ></vs-button>
          <vs-button
            type="flat"
            class="text-black"
            icon-pack="feather"
            :disabled="this.CurrentPage == 1"
            icon="icon-chevron-left"
            @click="GoBack()"
          ></vs-button>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="mt-4"
        >
          Page {{ CurrPage }} of {{ Math.ceil(Model.length / CountPerPage) }}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="mt-4"
        >
          <vs-button
            type="flat"
            class="text-black"
            icon-pack="feather"
            :disabled="
              this.CurrentPage == Math.ceil(Model.length / CountPerPage)
            "
            icon="icon-chevron-right"
            @click="GoNext()"
          ></vs-button>

          <vs-button
            type="flat"
            class="text-black"
            icon-pack="feather"
            icon="icon-chevrons-right"
            :disabled="
              this.CurrentPage == Math.ceil(Model.length / CountPerPage)
            "
            @click="GoToLastPage()"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>

  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      EnglishLan:false,
      CurrentPage: 1,
      CountPerPage: 4,
      Search: {

        PatientID:0
      },
      baseURL: domain,
      Model: []
    };
  },
  computed: {
    FilteredList() {
      return this.Model.slice(
        (this.CurrentPage-1)*this.CountPerPage,
        (this.CurrentPage-1)*this.CountPerPage + this.CountPerPage
      );
    },
    CurrPage() {

      return this.CurrentPage;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    Download(item, index) {
      debugger
      // this.$store.commit("UPDATE_PrescriptionsList", item);
      console.log(index);
      this.$router.push({ name: "PrescriptionPDF" ,params:{ID:item.PatientReservationSessionID} });
    },
    GoToFirstPage() {
      this.CurrentPage = 0;
    },
    GoBack() {
      this.CurrentPage = this.CurrentPage - 1;
    },
    GoToLastPage() {
      this.CurrentPage = Math.ceil(this.Model.length / this.CountPerPage);
    },
    GoNext() {
      this.CurrentPage = this.CurrentPage + 1;
    },
    SearchPrescription(){
             this.Search.PatientID= this.$store.state.AppActiveUser.Patient.ID

    this.$store
      .dispatch("patientList/SearchReservationSessionPrescriptions",  this.Search)
      .then(res => {
        if (res.status == 200) {
          this.Model = res.data.Data;
            if(this.Model==null||this.Model.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        }
      })
      .catch(() => {
        window.showError();
      });
    }
  },
  created() {
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
 this.SearchPrescription();
  }
};
</script>
<style>

</style>
